var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "project-form"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('router-link', {
          staticClass: "btn btn-link",
          attrs: {
            "to": {
              name: 'asset-projects-list'
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-1"
        })])];
      },
      proxy: true
    }])
  }, [[_vm._v("Edit Project")]], 2), _vm.asset._id ? _c('ProjectForm', {
    attrs: {
      "id": _vm.asset._id,
      "project-id": _vm.currentProject._id,
      "mode": "edit",
      "error": _vm.validationErrors
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "text-right pb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "data-cy": "update-project-btn",
      "disabled": _vm.loadingAction.updateProject
    },
    on: {
      "click": _vm.onUpdate
    }
  }, [_vm.loadingAction.updateProject || _vm.loadingAction.uploadProjectAttachment || _vm.loadingAction.deleteProjectAttachment ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Updating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Update Project")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }