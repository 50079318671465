<template>
  <div data-cy="project-form">
    <TabTitle class="mb-4">
      <template>Edit Project</template>
      <template #actions>
        <router-link class="btn btn-link" :to="{ name: 'asset-projects-list' }"><i class="fa fa-arrow-left mr-1"></i></router-link>
      </template>
    </TabTitle>
    <ProjectForm v-if="asset._id" :id="asset._id" v-model="form" :project-id="currentProject._id" mode="edit" :error="validationErrors" />
    <div class="text-right pb-4">
      <button type="submit" class="btn btn-primary" data-cy="update-project-btn" :disabled="loadingAction.updateProject" @click="onUpdate">
        <span v-if="loadingAction.updateProject || loadingAction.uploadProjectAttachment || loadingAction.deleteProjectAttachment"
          ><i class="fa fa-spin fa-spinner mr-1"></i> Updating...</span
        >
        <span v-else><i class="fa fa-check-circle mr-1"></i> Update Project</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import bluebird from 'bluebird';

import ProjectForm from '@/components/forms/ProjectForm';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetProjectEdit',
  components: {
    ProjectForm,
    TabTitle
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      projects: 'asset/projects',
      loadingAction: 'asset/loadingAction',
      errorAction: 'asset/errorAction',
      validationErrors: 'asset/validationErrors'
    }),
    currentProject() {
      return (this.projects || []).find(project => project._id === this.$route.params.projectId) || {};
    }
  },
  async mounted() {
    if (this.projects.length === 0) {
      await this.list({ params: { assetId: this.$route.params.id } });
    }

    this.form = {
      name: this.currentProject.name,
      description: this.currentProject.description,
      startDate: this.currentProject.startDate,
      endDate: this.currentProject.endDate,
      status: this.currentProject.status,
      accountIds: this.currentProject.accountIds,
      cost: this.currentProject.cost,
      documents: this.currentProject.documents || [],
      documentIds: this.currentProject.documentIds || [],
      uploads: [],
      deletedUploads: []
    };
  },
  methods: {
    ...mapActions({
      update: 'asset/updateProject',
      list: 'asset/listProjects',
      getAccounts: 'asset/accounts',
      uploadProjectAttachment: 'asset/uploadProjectAttachment',
      deleteProjectAttachment: 'asset/deleteProjectAttachment'
    }),
    async onUpdate() {
      if (this.form.uploads.length > 0) {
        const uploads = await bluebird.map(
          this.form.uploads,
          async file => {
            return await this.uploadProjectAttachment({
              data: file.file,
              id: this.$route.params.id
            });
          },
          {
            concurrency: 5
          }
        );
        this.form.documents = [...this.form.documents, ...uploads];
      }
      if (this.form.deletedUploads.length > 0) {
        bluebird.map(
          this.form.uploads,
          async file => {
            return await this.deleteProjectAttachment({
              documentId: file._id,
              id: this.$route.params.projectId
            });
          },
          {
            concurrency: 5
          }
        );
      }

      try {
        const editedForm = {
          ...this.form,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          accountIds: this.form.accountIds
        };

        delete editedForm.uploads;
        delete editedForm.deletedUploads;

        const updateProject = await this.update({ id: this.$route.params.projectId, data: editedForm });

        if (Object.keys(this.validationErrors).length > 0) {
          window.scrollTo(0, 0);
          return this.$toasted.error('Could not update project.');
        }

        if (updateProject._id) {
          this.$router.back();
          this.$toasted.success('Project updated successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not update project.');
      }
    }
  }
};
</script>
